html,
body,
#root {
    height: 100vh;
    background-color: #fff !important;

    img {
        width: 100%;
        height: auto;
    }

    table {
        width: 100%;
    }
}

.am-button {
    height: 35px !important;
    line-height: 33px !important;

    span {
        font-size: 14px !important;
    }
}

@supports (bottom: env(safe-area-inset-bottom)) {
    body {
        margin-bottom: env(safe-area-inset-bottom);
    }
}
.expert-compilation{
    text-align: center;
    margin: 20px 0;
    img{
        width: auto!important;
    }
}
@brand-primary: #FF800E;@brand-primary-tap: #FF800E;@color-text-base: #333;@color-text-base-inverse: #fff;